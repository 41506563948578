import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Header = ({
    user: {
        accessToken,
        firstName,
        picture,
    },
}) => {
    const handleLogin = (e) => {
        e.preventDefault()
        window.location.href = '/auth/redirect'
    }
    return (
        <header id="header">
            <Helmet>
                <title>MV Data Center</title>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no"/>
            </Helmet>
            <div className="inner">
                <img src="/assets/mv-data-logo-flat.png" href="login" className="logo"/>
                <nav id="nav">
                    <Link to="/">Home</Link>
                    <Link to="/contact">Contact Us</Link>
                    <div className="dropdown">
                        <Link to="/documentation">Resources</Link>
                        <div className="dropdown-content">
                            <Link to="/documentation">Documentation</Link>
                            <Link to="/pricing">Pricing</Link>
                            <Link to="/about">About</Link>
                        </div>
                    </div>
                    <a onClick={handleLogin}>Login</a>
                </nav>
            </div>
        </header>
    )
}

export default Header
