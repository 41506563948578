import React from 'react'
import styled from 'styled-components'
import { background } from '../../colors'
import { Outlet } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

const StyledBody = styled.div`
  background: ${background};
  height: 100%;
  min-height: 800px;
`
const GlobalStyle = createGlobalStyle`
  body {
    background: ${background};
  }
`

const Body = ({ renderToastContainer }) => {
    return (
        <>
            <GlobalStyle/>
            <StyledBody>
                <Outlet />
                { renderToastContainer() }
            </StyledBody>
        </>
    )
}

export default Body
