import * as types from './types'

export const addError = (error) => ({
    type: types.ADD_ERROR,
    error,
})

export const removeError = (error) => ({
    type: types.REMOVE_ERROR,
    error,
})
