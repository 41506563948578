import React from 'react'

const About = () => {
    return (
        <>
            <section id="one">
                <div className="inner">
                    <div className="image right">
                        <img src="/assets/new_opportunity.jpg" alt="" />
                    </div>
                    <header>
                        <h2>Unlock new opportunities</h2>
                    </header>
                    <p> We use our proprietary hardware and cooling technologies to bring you computing engines with performance to price ratio that can be an order of magnitude higher than what is delivered by the traditional cloud providers. What can an order of magnitude gain in computing performance mean to your company?</p>
                </div>
            </section>
            <section id="two">
                <div className="inner">
                    <article>
                        <div className="content">
                            <header>
                                <h3>Optimized Performance</h3>
                            </header>
                            <div className="image fit">
                                <img src="/assets/complex_code.jpg" alt="" />
                            </div>
                            <p> Heavily cooled machines can perform far faster and with far lower latency than the under-clocked machines provided by traditional cloud providers. As a result heavy compute web requests handled by one machine such as model inference have no match.</p>
                        </div>
                    </article>
                    <article className="alt">
                        <div className="content">
                            <header>
                                <h3>Any scale on demand</h3>
                            </header>
                            <div className="image fit">
                                <img src="/assets/scale.jpg" alt=""/>
                            </div>
                            <p>We provide ultra-low prices on demand at small and large scales. Save time and money by diverting your
                                engineering time towards your core product rather than costly code optimizations. With heavy cooling our machines draw significantly less power than the industry standard and our power is also 100% coming from renewable sources.</p>
                        </div>
                    </article>
                </div>
            </section>
            <section id="three">
                <div className="inner">
                    <article>
                        <div className="content">
                            <header>
                                <h3>Simple to use</h3>
                            </header>
                            <div className="image fit">
                                <img src="/assets/simplicity.jpg" alt=""/>
                            </div>
                            <p>We work with the most standardized infrastructure solutions on the market. Our goal is to make sure you will never be locked to a single vendor.
                                Our team of infrastructure engineers can help you make your infrastructure more reliable by working in multiple clouds. </p>
                        </div>
                    </article>
                    <article>
                        <div className="content">
                            <header>
                                <h3>Maximum Availability</h3>
                            </header>
                            <div className="image fit">
                                <img src="/assets/availability.jpg" alt=""/>
                            </div>
                            <p> Our technologies ensure there is always excess compute power available giving you the certainty of low on demand prices at any time.</p>
                            <ul className="actions">
                                <li><a href="/contact" className="button alt">Learn More</a></li>
                            </ul>
                        </div>
                    </article>
                </div>
            </section>
        </>
    )
}

export default About
