import React from 'react'
import * as Sentry from '@sentry/react'

import { createRoot } from 'react-dom/client'
import { Provider, ReactReduxContext } from 'react-redux'
import { HistoryRouter } from 'redux-first-history/rr6'

import App from './App'
import setupStore from './state/setupStore'

const container = document.getElementById('root')
const root = createRoot(container)

const { user, consoleAPIURL } = window.__SERVER_DATA__
const { store, history } = setupStore({ user }, { consoleAPIURL })

if (process.env.NODE_ENV !== 'development') {
    delete window.__SERVER_DATA__
}

const initialize = (Component) => root.render(
    <Provider store={ store } context={ ReactReduxContext }>
        <HistoryRouter history={ history } context={ ReactReduxContext }>
            <Component />
        </HistoryRouter>
    </Provider>
)

initialize(App)

Sentry.init({
    dsn: 'https://d7637b22a4236e376de70e1442150306@o4505600501678080.ingest.sentry.io/4505715052707840',
    integrations: [],
    environment: process.env.NODE_ENV,
    release: process.env.RELEASE || 'local',
    beforeSend: (event) => {
        if (process.env.NODE_ENV === 'development') {
            return null
        }
        return event
    },
})

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', () => {
        initialize(require('./App').default)
    })
}
