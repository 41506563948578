import Layout from './Layout'
import { connect } from 'react-redux'
import { errorActions } from '../../state/ducks/errors'

const mapStateToProps = ({ user, errors, modal }) => ({ 
    user,
    errors,
    modal,
})

const mapPropsToDispatch = {
    removeError: errorActions.removeError,
}

export default connect(
    mapStateToProps,
    mapPropsToDispatch,
)(Layout)
