import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga'
import '../../assets/css/app.css'

const tagManagerArgs = {
    gtmId: 'GTM-TJP4CNC'
}
const initGA = () => {
    ReactGA.initialize('UA-177328195-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
}

const Main = () => {
    useEffect(() => {
        initGA()
        TagManager.initialize(tagManagerArgs)
    }, [])

    return (
        <>
            {/* <StaticLanding></StaticLanding> */}
            <div>
                {/*
	Introspect by TEMPLATED
	templated.co @templatedco
	Released for free under the Creative Commons Attribution 3.0 license (templated.co/license)
*/}
                <section id="banner">
                    <div className="inner">
                        <h1>MV Data: <span>Bringing you the Next Generation of Performance Computing<br/></span></h1>
                        <ul className="actions">
                            <li><a href="/contact" className="button alt">Get Started</a></li>
                        </ul>
                    </div>
                </section>
            </div>

        </>
    )
}

export default Main
