import React from 'react'
import { Link } from 'react-router-dom'

const Documentation = () => {
    return (
        <>
            <section id="one">
                <div className="inner">
                    <header>
                        <h2>Our Services</h2>
                    </header>
                    <p> We provide high-performance computing engines with a performance to price ratio that is
                        significantly higher than traditional cloud providers. Our proprietary hardware and cooling
                        technologies enable us to deliver this unmatched performance. Our services are accessible via
                        our SDK and Terraform provider.
                    </p>
                    <header>
                        <h2>Experienced Consultants</h2>
                    </header>
                    <p>
                        We offer experienced consultants who can assist in setting up and managing multicloud
                        infrastructure. Our team has extensive knowledge and experience in various cloud platforms and
                        can help you optimize your infrastructure for performance, cost, and reliability. Whether you are
                        just starting your multicloud journey or looking to optimize your existing setup, our
                        consultants can provide the guidance and support you need.
                    </p>
                    <header>
                        <h2>GO SDK</h2>
                    </header>
                    <p> Our SDK, available in Go, provides a set of tools for programmatic interaction with our
                        services. It includes methods for resource management, authentication handling, and response
                        processing. Visit <a href="https://github.com/mvdatacenter/mvdata-sdk-go/">our SDK</a> for more
                        information.
                    </p>
                    <header>
                        <h2>Terraform Provider</h2>
                    </header>
                    <p>
                        For larger projects, we recommend using our Terraform provider. With it, you can automate the provisioning and management of our cloud infrastructure, ensuring idempotent, consistent, and repeatable deployments. With IaC, we can version control our infrastructure setup, enabling us to track changes, roll back if necessary, and collaborate effectively. For more information, visit <a href="https://github.com/mvdatacenter/terraform-provider-mvdata">our Terraform provider</a>.
                    </p>
                    <Link to="/pricing">
                        <button>See Pricing</button>
                    </Link>
                </div>
            </section>
        </>
    )
}

export default Documentation
