import React from 'react'
import styled from 'styled-components'
import { FaBars } from 'react-icons/fa'
import Header from './Header'
import Body from './Body'
import ReactToastify from '../../components/ReactToastify'

import 'react-toastify/dist/ReactToastify.css'

const S = {}
S.IconContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
S.FaBars = styled(FaBars)`
    cursor: pointer;
`

const Layout = ({
    user,
    errors,
    removeError,
}) => {
    const [ shouldLoadToastify, setShouldLoadToastify ] = React.useState(false)

    React.useEffect(() => {
        if (errors.length && !shouldLoadToastify) {
            setShouldLoadToastify(true)
        }
    }, [errors, shouldLoadToastify])

    return (
        <>
            <Header
                user={ user }
            />
            <Body
                renderToastContainer={() => (
                    shouldLoadToastify && <ReactToastify render={(Toastify) => <Toastify.ToastContainer />} />
                )}
            />
            { errors.map((error) => (
                <ReactToastify
                    key={ error.toString() }
                    render={(Toastify) => {
                        Toastify.toast.error(error.toString())
                        removeError(error)
                        return null
                    }}
                />
            ))}
        </>
    )
}

export default Layout
