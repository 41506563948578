import React from 'react'
import loadable from '@loadable/component'

const LazyReactToastify = loadable.lib(() => import('react-toastify'))

const ReactToastify = (props) => (
    <LazyReactToastify fallback={ null }>
        { props.render }
    </LazyReactToastify>
)

export default ReactToastify
