import * as types from './types'

const errors = (state = [], { type, error }) => {
    switch (type) {
        case types.ADD_ERROR:
            return [ ...state, error ]
        case types.REMOVE_ERROR:
            return state.filter((err) => err !== error)
        default:
            return state
    }
}

export default errors
