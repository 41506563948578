import { createError } from './error'

export const httpUtil = (url, options = {}) => {
    const defaultHeaders = {
        'Content-Type': 'application/json'
    }
    const { accessToken, body, ...fetchSettings } = options

    let requestBody = body
    if (typeof requestBody === 'object') {
        requestBody = JSON.stringify(requestBody)
    }

    return fetch(url, {
        mode: 'cors',
        ...fetchSettings,
        body: requestBody,
        headers: {
            ...defaultHeaders,
            ...(fetchSettings.headers ? fetchSettings.headers : {}),
            ...(accessToken ? {
                'Authorization': `Bearer ${accessToken}`,
            } : {})

        },
    })
    .catch((error) => {
        throw createError(`${error.message}: ${url}`, 502)
    })
    .then((response) => {
        if (!response.ok) {
            throw createError(response.statusText, response.status)
        }
        return response.json()
    })
}
