import React from 'react'

const Pricing = () => {
    const pricingData = [
        { gpus: '4x NVIDIA A100 PCIe', vramPerGpu: '40 GB', vcpus: 24, ram: '200 GiB', storage: '512 GiB SSD', price: '$4.00 / hr' },
        { gpus: '2x NVIDIA A100 PCIe', vramPerGpu: '40 GB', vcpus: 24, ram: '200 GiB', storage: '512 GiB SSD', price: '$2.09 / hr' },
        { gpus: '1x NVIDIA A100 PCIe', vramPerGpu: '40 GB', vcpus: 24, ram: '200 GiB', storage: '512 GiB SSD', price: '$1.09 / hr' },
        { gpus: '1x NVIDIA RX 3090', vramPerGpu: '24 GB', vcpus: 24, ram: '200 GiB', storage: '512 GiB SSD', price: '$0.65 / hr' },
        { gpus: '2x NVIDIA RX 3090', vramPerGpu: '24 GB', vcpus: 24, ram: '200 GiB', storage: '512 GiB SSD', price: '$1.22 / hr' },
        { gpus: '4x NVIDIA RX 3090', vramPerGpu: '24 GB', vcpus: 24, ram: '200 GiB', storage: '512 GiB SSD', price: '$2.43 / hr' },
        { gpus: '1x AMD RX 590', vramPerGpu: '8 GB', vcpus: 24, ram: '200 GiB', storage: '512 GiB SSD', price: '$0.25 / hr' },
        { gpus: '2x AMD RX 590', vramPerGpu: '8 GB', vcpus: 24, ram: '200 GiB', storage: '512 GiB SSD', price: '$0.45 / hr' },
        { gpus: '4x AMD RX 590', vramPerGpu: '8 GB', vcpus: 24, ram: '200 GiB', storage: '512 GiB SSD', price: '$0.85 / hr' }
    ]
    return (
        <>
            <section id="one">
                <div className="inner">
                    <header>
                        <h2>On-demand GPU cloud pricing</h2>
                        <p>Access high power GPUs when you need them and only pay for what you use.</p>
                    </header>
                    <table className="pricing">
                        <thead>
                            <tr>
                                <th>GPUs</th>
                                <th>vRAM / GPU</th>
                                <th>vCPUs</th>
                                <th>RAM</th>
                                <th>STORAGE</th>
                                <th>PRICE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pricingData.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.gpus}</td>
                                    <td>{data.vramPerGpu}</td>
                                    <td>{data.vcpus}</td>
                                    <td>{data.ram}</td>
                                    <td>{data.storage}</td>
                                    <td>{data.price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default Pricing
