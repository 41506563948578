import React from 'react'
import emailjs from 'emailjs-com'
import styled from 'styled-components'

const StyledFooter = styled.section`
  // Add your styles here. For example:
  background: #8C53A0;
  color: white;
`

const About = () => {
    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm('gmail', 'template_oTnayuRN', e.target, 'user_3e0VIkZBbR28aUmEVEajw')
        .then(() => {
            // console.log(result.text)
        }, () => {
            // console.log(error.text)
        })
    }

    return (
        <StyledFooter id="footer">
            <div className="inner">
                <header>
                    <h2>Get in Touch</h2>
                </header>
                <form className="contact-form" onSubmit={sendEmail}>
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name"/>
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email"/>
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows={6} defaultValue={''}/>
                    </div>
                    <ul className="actions">
                        <li><input type="submit" defaultValue="Send Message" className="alt"/></li>
                    </ul>
                </form>
            </div>
        </StyledFooter>
    )
}

export default About
