import React from 'react'
import '../../assets/css/app.css'
import { Route, Routes } from 'react-router-dom'

import About from './About'
import Contact from './Contact'
import Documentation from './Documentation'
import Main from './Main'
import Pricing from './Pricing'

import Layout from './components/Layout'

const App = () => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="/" element={<Main />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/documentation" element={<Documentation />} />
                <Route path="*" element={<h1>Not Found</h1>} />
            </Route>
        </Routes>
    )
}

export default App
