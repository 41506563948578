import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import * as reducers from './ducks'
import { httpUtil } from '../utils/http'

const isServer = typeof module !== 'undefined' && module.exports

const setupStore = (initialState = {}, config = {}) => {
    const urlPath = config.urlPath || '/'
    const {
        routerMiddleware,
        createReduxHistory,
        routerReducer
    } = createReduxHistoryContext({
        history: isServer ? createMemoryHistory({ initialEntries: [ urlPath ] }) : createBrowserHistory()
    })
    const services = {
        dataAPI: (endpoint, options) => httpUtil(
            `${config.consoleAPIURL}${endpoint}`,
            options,
        )
        .catch((err) => {
            if (err.status === 401) {
                window.location.href = '/'
                return
            }
            throw err
        }),
        ...(config.services ? config.services : {})
    }
    const store = configureStore({
        reducer: combineReducers({
            router: routerReducer,
            ...reducers,
            user: (state = {}) => state,
        }),
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({
            serializableCheck: false,
            thunk: {
                extraArgument: services
            }
        }),
        routerMiddleware,
        ...(process.env.NODE_ENV === 'development' ? [ require('redux-logger').default ] : []),
        ]
    })
    if (!isServer && process.env.NODE_ENV === 'development') {
        window.store = store
    }
    const history = createReduxHistory(store)
    return {
        history,
        store,
    }
}

export default setupStore
